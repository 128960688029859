import React from 'react'

const BacktestDoc = ({
  width = 32,
  height = 32,
  color = '#000000'
}) => {
  return (
    <svg viewBox="0 0 76.7 76.7" width={width} height={height}>
      <g>
        <path
          fill={color}
          d="M45.8,9.4H21.5c-2.5,0-4.6,2-4.6,4.6v48.7c0,2.5,2,4.6,4.6,4.6h33.7c2.5,0,4.6-2,4.6-4.6V23.4L45.8,9.4z M55.7,25v0.5H45.6
          c-0.2,0-0.4-0.3-0.4-0.7V14.5L55.7,25z M55.2,63.2H21.5c-0.3,0-0.6-0.2-0.6-0.6V14c0-0.3,0.2-0.6,0.6-0.6h19.7v11.5
          c0,2.6,2,4.7,4.4,4.7h10.1v33.1C55.7,63,55.5,63.2,55.2,63.2z M50.7,41.1c0,1.1-0.9,2-2,2H27.5c-1.1,0-2-0.9-2-2s0.9-2,2-2h21.2
          C49.8,39.1,50.7,40,50.7,41.1z M42.7,50.8c0,1.1-0.9,2-2,2H27.5c-1.1,0-2-0.9-2-2s0.9-2,2-2h13.2C41.8,48.8,42.7,49.7,42.7,50.8z"
        />
      </g>
    </svg>
  )
}

export default BacktestDoc
