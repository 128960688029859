import React, { useState } from 'react'
import moment from 'moment'

import styles from '../Styles/BacktestTable.module.css'
import Loader from './Loader'
import WinLossBar from './WinLossBar'
import ShowLtp from './ShowLtp'
import { getMetaObj } from '../common'
import VisualChart from './VisualChart'
import TransactionLog from './TransactionLog'

const headersMap = [
  { label: 'Instrument', width: '16%', valueKey: 'instrument', },
  { label: 'LTP', width: '17%', valueKey: 'ltp', },
  { label: 'Backtest P&L', width: '18%', valueKey: 'backtestPnL', },
  { label: 'Wins Vs Losses', width: '15%', valueKey: 'winloss', },
  { label: 'Signals', width: '6%', valueKey: 'total_number_of_signals', },
  { label: 'Wins', width: '6%', valueKey: 'win_count', },
  { label: 'Losses', width: '6%', valueKey: 'loss_count', },
  { label: 'WS', width: '4%', valueKey: 'winning_streak', },
  { label: 'LS', width: '4%', valueKey: 'lossing_streak', },
  { label: 'Max DD', width: '8%', valueKey: 'max_draw', },
]

const getFormattedTime = (date) => {
  if (date) {
    return moment(date, 'DD/MM/YYYY').format('DD MMM YYYY')
  }
  return ''
}

const instrumentRenderer = (segSym, width) => {
  const [seg = '', sym = ''] = segSym.split('_')
  return (
    <div className={styles.segSym} style={{ width }}>
      <p className={styles.symbol}>{sym}</p>
      <p className={styles.segment}>{seg}</p>
    </div>
  )
}
const winLossBarRender = (win_count, loss_count, width) => {
  return (
    <div style={{ width, margin: '0 5px' }}>
      <WinLossBar
        winCount={win_count}
        lossCount={loss_count}
      />
    </div>
  )
}
const headerCellRenderer = (value, width) => {
  return (
    <p className={styles.headerCell} style={{ width }}>{value}</p>
  )
}
const ltpCellRenderer = (segSym, width) => {
  return (
    <ShowLtp
      seg_sym={segSym}
      containerStyles={{ width, justifyContent: 'flex-start' }}
    />
  )
}

const BacktestTable = React.memo(({
  backtestData,
  stock = '',
  isFetching,
  displayDevice,
}) => {
  if (isFetching) return <Loader />
  const {
    backtest_results = [], start_time, stop_time, dt_start, dt_stop
  } = backtestData
  const reqResult = backtest_results[0]
  if (!isFetching && !reqResult) return <p className={styles.noData}>Not able to fetch data</p>
  const { backtest_result, seg_sym = '', } = reqResult
  const reqData = backtest_result[stock] || {}
  const {
    total_number_of_signals = 0.0,
    win_count = 0.0,
    loss_count = 0.0,
    winning_streak = 0.0,
    lossing_streak = 0.0,
    maximum_gain = 0.0,
    maximum_loss = 0.0,
    average_gain_per_winning_trade = 0.0,
    average_gain_per_losing_trade = 0.0,
    max_draw = 0.0,
    period_high = 0.0,
    period_low = 0.0,
    period_return = 0.0,
    symbol,
    error_msg,
    error,
    exchange = '',
    final_pnl = 0.0,
    return: final_return = 0.0,
    trade_log = [],
  } = reqData
  const [showTransaction, toggleTransaction] = useState(false)
  const renderDetailsRow = (label, value, width) => (
    <div className={styles.detailsRow} style={{ width }}>
      <p className={styles.detailsLabel}>{label}</p>
      <p className={styles.detailsValue}>{value}</p>
    </div>
  )
  const segSym = seg_sym || symbol
  const pnlCellRenderer = (width) => {
    if (error || error_msg) {
      return <p>ERROR</p>
    }
    const [seg = '', sym = ''] = segSym.split('_')
    const metaObj = getMetaObj(`${seg}_${sym}`, exchange || seg)
    const precision = metaObj.quotePrecision || 4
    const currency = metaObj.quoteCurrency || ''
    const classColor = final_pnl >= 0 ? 'up' : 'down'
    const disp_ret = +final_return >= 0 ? `(+${+final_return.toFixed(2)} %)` : `(${+final_return.toFixed(2)} %)`
    return (
      <p className={`${styles.pnlCell} ${styles[classColor]}`} style={{ width }}>
        {`${Number(final_pnl.toFixed(precision))} ${currency || ''} ${disp_ret} `}
      </p>
    )
  }
  const chartData = trade_log.map(log => ({ y: log[5].toFixed(3), x: log[0] }))
  const bt_start_time = getFormattedTime(dt_start || start_time)
  const bt_stop_time = getFormattedTime(dt_stop || stop_time)
  return (
    <div className={styles.container}>
      <div className={styles.rowHeader}>
        {headersMap.map(header => {
          const { label, width } = header
          return (
            <p key={label} className={styles.headerCell} style={{ width }}>{label}</p>
          )
        })}
      </div>
      <div className={styles.content}>
        <div className={styles.row}>
          {instrumentRenderer(segSym, headersMap[0].width)}
          {ltpCellRenderer(segSym, headersMap[1].width)}
          {pnlCellRenderer(headersMap[2].width)}
          {winLossBarRender(win_count, loss_count, headersMap[3].width)}
          {headerCellRenderer(total_number_of_signals, headersMap[4].width)}
          {headerCellRenderer(win_count, headersMap[5].width)}
          {headerCellRenderer(loss_count, headersMap[6].width)}
          {headerCellRenderer(winning_streak, headersMap[7].width)}
          {headerCellRenderer(lossing_streak, headersMap[8].width)}
          {headerCellRenderer(`${(+max_draw).toFixed(2)} %`, headersMap[9].width)}
        </div>
        <div className={styles.expandedDetails}>
          <div className={styles.chartContianer}>
            <VisualChart
              id={segSym}
              data={chartData}
            />
          </div>
          <div className={styles.detailsCol1}>
              {renderDetailsRow('Total number of signals', total_number_of_signals)}
              {renderDetailsRow('Number of wins', win_count)}
              {renderDetailsRow('Number of losses', loss_count)}
              {renderDetailsRow('Winning streak', winning_streak)}
              {renderDetailsRow('Losing streak', lossing_streak)}
              {renderDetailsRow('Max gains', maximum_gain.toFixed(4))}
              {renderDetailsRow('Max loss', maximum_loss.toFixed(4))}
              {renderDetailsRow('Avg gain/winning trade', average_gain_per_winning_trade.toFixed(4))}
              {renderDetailsRow('Avg loss/losing trade', average_gain_per_losing_trade.toFixed(4))}
              {renderDetailsRow('Max Drawdown', `${(+max_draw).toFixed(4)} %`)}
          </div>
          <div className={styles.detailsCol2}>
            {renderDetailsRow('Period High', period_high.toFixed(2))}
            {renderDetailsRow('Period Low', period_low.toFixed(2))}
            {renderDetailsRow('Period return', `${(+period_return).toFixed(2)} %`)}
            {renderDetailsRow('Backtest period', `${bt_start_time} - ${bt_stop_time}`)}
          </div>
        </div>
        <div className={styles.transactionSection}>
          <div className={styles.transactionTable}>
            <button
              type="button"
              onClick={() => toggleTransaction(!showTransaction)}
              className={styles.transactionHeader}
            >
              Transaction Details
              {/* {showTransaction ? <ArrowFilledUp color="#253bde" /> : <ArrowFilledDown color="#253bde" />} */}
            </button>
            {showTransaction ? (
              <TransactionLog
                data={trade_log}
                mobileView={displayDevice === 'mobile'}
              />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  )
})

export default BacktestTable
