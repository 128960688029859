import React, { useState } from 'react'
import moment from 'moment'

import styles from '../Styles/BacktestTableResp.module.css'
import Loader from './Loader'
import WinLossBar from './WinLossBar'
import ShowLtp from './ShowLtp'
import { getMetaObj } from '../common'
import VisualChart from './VisualChart'
import TransactionLog from './TransactionLog'
import PerformanceMetrics from './PerformanceMetrics'
import Modal from './Modal'
import BacktestGraph from '../Icons/BacktestGraph'
import Performance from '../Icons/Performance'
import BacktestDoc from '../Icons/BacktestDoc'

const TABS = {
  GRAPH: 'graph',
  PERFORMANCE_METRICS: 'performance',
  TRADE_LOG: 'tradeLog',
}

const getFormattedTime = (date) => {
  if (date) {
    return moment(date, 'DD/MM/YYYY').format('DD MMM YYYY')
  }
  return ''
}

const instrumentRenderer = (segSym) => {
  const [seg = '', sym = ''] = segSym.split('_')
  return (
    <div className={styles.segSym}>
      <p className={styles.symbol}>{sym}</p>
      <p className={styles.segment}>{seg}</p>
    </div>
  )
}
const winLossBarRender = (win_count, loss_count) => {
  return (
    <WinLossBar
      winCount={win_count}
      lossCount={loss_count}
    />
  )
}

const getIconColor = (selected) => {
  if (selected) return '#253bde'
  return '#22283c'
}

const ltpCellRenderer = (segSym) => {
  return (
    <ShowLtp
      seg_sym={segSym}
    />
  )
}

const BacktestTableResp = React.memo(({
  backtestData,
  stock = '',
  isFetching,
  displayDevice,
}) => {
  if (isFetching) return <Loader />
  const {
    backtest_results = [], start_time, stop_time, dt_start, dt_stop
  } = backtestData
  const reqResult = backtest_results[0]
  if (!isFetching && !reqResult) return <p className={styles.noData}>Not able to fetch data</p>
  const { backtest_result, seg_sym = '', } = reqResult
  const reqData = backtest_result[stock] || {}
  const [selectedTab, toggleView] = useState(TABS.GRAPH)
  const {
    total_number_of_signals,
    win_count,
    loss_count,
    winning_streak,
    lossing_streak,
    maximum_gain,
    maximum_loss,
    average_gain_per_winning_trade,
    average_gain_per_losing_trade,
    max_draw,
    period_high,
    period_low,
    period_return,
    symbol,
    error_msg,
    error,
    exchange = '',
    final_pnl = 0.0,
    return: final_return = 0.0,
    trade_log = [],
  } = reqData
  const segSym = seg_sym || symbol
  const [seg = '', sym = ''] = segSym.split('_')
  const metaObj = getMetaObj(`${seg}_${sym}`, exchange || seg)
  const precision = metaObj.quotePrecision || 4
  const pnlCellRenderer = () => {
    if (error || error_msg) {
      return <p>ERROR</p>
    }
    const currency = metaObj.quoteCurrency || ''
    const classColor = final_pnl >= 0 ? 'up' : 'down'
    const disp_ret = +final_return >= 0 ? `(+${+final_return.toFixed(2)} %)` : `(${+final_return.toFixed(2)} %)`
    return (
      <p className={`${styles.pnlCell} ${styles[classColor]}`}>
        {`${Number(final_pnl.toFixed(precision))} ${currency || ''} ${disp_ret} `}
      </p>
    )
  }
  const chartData = trade_log.map(log => ({ y: log[5].toFixed(3), x: log[0] }))
  const bt_start_time = getFormattedTime(dt_start || start_time)
  const bt_stop_time = getFormattedTime(dt_stop || stop_time)
  return (
    <div className={styles.container}>
      <Modal
        open={TABS.TRADE_LOG === selectedTab}
        onDismiss={() => toggleView(TABS.GRAPH)}
        title="Transaction Details"
      >
        <TransactionLog
          data={trade_log}
          mobileView={displayDevice === 'mobile'}
        />
      </Modal>
      <div className={styles.header}>
        {instrumentRenderer(segSym)}
        <div className={styles.subTitle}>
          <p className={styles.subTitleLabel}>
            H &nbsp;
            <span className={styles.subTitleValue}>{period_high}</span>
          </p>
          <p className={styles.subTitleLabel}>
            L &nbsp;
            <span className={styles.subTitleValue}>{period_low}</span>
          </p>
          <p className={styles.subTitleLabel}>
            R &nbsp;
            <span className={styles.subTitleValue}>{period_return.toFixed(precision)}</span>
          </p>
        </div>
      </div>
      <div className={styles.subHeader}>
        {pnlCellRenderer()}
        {ltpCellRenderer(segSym)}
      </div>
      <div className={styles.content}>
        {selectedTab === TABS.GRAPH ? (
          <>
            <div className={styles.chartContianer}>
                <VisualChart
                    id={segSym}
                    data={chartData}
                />
            </div>
            <div className={styles.footer}>
                {winLossBarRender(win_count, loss_count)}
                <div className={styles.maxdd}>
                    <p className={styles.label}>
                    Max. DD
                    <span className={styles.value}>{`${(+max_draw).toFixed(2)} %`}</span>
                    </p>
                </div>
            </div>
          </>
        ) : null}
        {selectedTab === TABS.PERFORMANCE_METRICS ? (
          <PerformanceMetrics
            total_number_of_signals={total_number_of_signals}
            winning_streak={winning_streak}
            lossing_streak={lossing_streak}
            maximum_gain={maximum_gain}
            maximum_loss={maximum_loss}
            average_gain_per_winning_trade={average_gain_per_winning_trade}
            average_gain_per_losing_trade={average_gain_per_losing_trade}
            max_draw={max_draw}
            precision={precision}
          />
        ) : null}
        <div className={styles.actionBar}>
          <button
            className={`${styles.roundButton} ${selectedTab === TABS.GRAPH ? styles.selectedTab : ''}`}
            type="buttton"
            onClick={() => toggleView(TABS.GRAPH)}
          >
            <BacktestGraph color={getIconColor(selectedTab === TABS.GRAPH)} height={22} width={22} />
          </button>
          <button
            className={`${styles.roundButton} ${selectedTab === TABS.PERFORMANCE_METRICS ? styles.selectedTab : ''}`}
            type="buttton"
            onClick={() => toggleView(TABS.PERFORMANCE_METRICS)}
          >
            <Performance color={getIconColor(selectedTab === TABS.PERFORMANCE_METRICS)} height={22} width={22} />
          </button>
          <button
            className={`${styles.roundButton} ${selectedTab === TABS.TRADE_LOG ? styles.selectedTab : ''}`}
            type="buttton"
            onClick={() => toggleView(TABS.TRADE_LOG)}
          >
            <BacktestDoc color={getIconColor(selectedTab === TABS.TRADE_LOG)} height={22} width={22} /> 
          </button>
        </div>
      </div>
    </div>
  )
})

export default BacktestTableResp
