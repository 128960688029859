import React from 'react'

import styles from '../Styles/Modal.module.css'
import MenuClose from '../Icons/MenuClose'

const Modal = React.memo(({
  showCloseBtn = true,
//   fullHeight = true,
  title,
  children,
  open,
  onDismiss
}) => {
  let display = 'none'
  let position = 'unset'
  let opacity =  0
  if (open) {
    position = 'fixed'
    opacity = 1
    display = 'block'
  }
  
  return (
    <div className={styles.modal_container} style={{ display, opacity, position }}>
      <div className={styles.modal_header}>
        <p className={styles.modal_title}>{title}</p>
        {showCloseBtn ? (
          <button
            type="button"
            onClick={() => onDismiss()}
            className={styles.modal_closeBtn}
          >
            <MenuClose height={18} width={18} />
          </button>
        ) : null}
      </div>
      {children}
    </div>
  )
})

export default Modal