import React from 'react'

const Performance = ({
  width = 32,
  height = 32,
  color = '#000000',
}) => {
  return (
    <svg viewBox="0 0 76.7 76.7" width={width} height={height}>
      <g>
        <path
          fill={color}
          d="M5.4,51.3c0,2.8,0.4,5.7,1.1,8.4c0.3,1.1-0.4,2.2-1.4,2.4c-0.2,0-0.3,0.1-0.5,0.1c-0.9,0-1.7-0.6-1.9-1.5
          c-0.8-3.1-1.2-6.3-1.2-9.4C1.4,31,18,14.4,38.3,14.4c5.7,0,11.2,1.3,16.2,3.8c1,0.5,1.4,1.7,0.9,2.7c-0.5,1-1.7,1.4-2.7,0.9
          c-4.5-2.2-9.4-3.3-14.5-3.3C20.2,18.4,5.4,33.2,5.4,51.3z M72,36.3c-0.5-1-1.6-1.5-2.6-1c-1,0.5-1.5,1.6-1,2.6
          c1.9,4.2,2.9,8.8,2.9,13.4c0,2.8-0.4,5.7-1.1,8.4c-0.3,1.1,0.4,2.2,1.4,2.4c0.2,0,0.3,0.1,0.5,0.1c0.9,0,1.7-0.6,1.9-1.5
          c0.8-3.1,1.2-6.3,1.2-9.4C75.3,46.1,74.2,41,72,36.3z M52.2,58.4c0,0.7-0.1,1.4-0.2,2.1c-0.2,1-1,1.7-2,1.7H26.7c-1,0-1.8-0.7-2-1.7
          c-0.1-0.8-0.2-1.5-0.2-2.1c0-7.6,6.2-13.8,13.8-13.8c2.3,0,4.4,0.6,6.3,1.5l24.1-27.5c0.7-0.8,2-0.9,2.8-0.2c0.8,0.7,0.9,2,0.2,2.8
          L47.9,48.4C50.5,51,52.2,54.5,52.2,58.4z M48.2,58.3c-0.1-5.3-4.5-9.7-9.8-9.7s-9.7,4.3-9.8,9.7H48.2z"
        />
      </g>
    </svg>
  )
}

export default Performance
