import React, { Component } from 'react'
import Chartjs from 'chart.js/dist/Chart.bundle.min'
import isEqual from 'lodash/isEqual'
import { chart_options } from '../Constant'

class VisualChart extends Component {
  constructor(props) {
    super(props)
    this.chartRef = React.createRef()
    this.chart = null
    this.rendered = false
    this.initiated = true
  }

  componentDidMount = () => {
    this.initiateChart()
  }

  shouldComponentUpdate = (nextProps) => {
    const { data } = this.props
    if (!isEqual(data, nextProps.data)) {
      this.chart.data.datasets[0].data = nextProps.data
      this.context = this.chartRef.current.getContext('2d')
      const gradient = this.context.createLinearGradient(0, 0, 0, 500)
      let addColor1 = 'rgba(6,208,146, 0.35)'
      let addColor2 = 'rgba(255,255,255, 0)'
      let lineColor = '#1ec18f'
      if (nextProps.data[nextProps.data.length - 1] && nextProps.data[nextProps.data.length - 1].y < 0) {
        addColor1 = 'rgba(255,66,88, 0)'
        addColor2 = 'rgba(255,66,88, 0.35)'
        lineColor = '#ff4258'
      }
      gradient.addColorStop(0, addColor1)
      gradient.addColorStop(0.5, addColor2)
      this.chart.data.datasets[0].backgroundColor = gradient
      this.chart.data.datasets[0].borderColor = lineColor
      this.chart.data.datasets[0].strokeColor = lineColor
      this.chart.resize()
      this.chart.update({
        duration: 500,
        easing: 'easeOutBounce',
        lazy: true,
      })
      this.rendered = false
      if (data.length === 0) {
        this.initiated = false
      }
      return true
    }
    return false
  }

  initiateChart = () => {
    const {
      label,
      data,
      format = 'day',
    } = this.props
    this.context = this.chartRef.current.getContext('2d')
    const gradient = this.context.createLinearGradient(0, 0, 0, 500)
    let addColor1 = 'rgba(6,208,146, 0.35)'
    let addColor2 = 'rgba(255,255,255, 0)'
    let positive = true
    if (data[data.length - 1] && data[data.length - 1].y < 0) {
      addColor1 = 'rgba(255,66,88, 0)'
      addColor2 = 'rgba(255,66,88, 0.35)'
      positive = false
    }
    gradient.addColorStop(0, addColor1)
    gradient.addColorStop(0.5, addColor2)
    this.chart = new Chartjs(this.context,
      chart_options(label, gradient, [], format, positive, { menuItem: '#383838', textLight: '#88888a' }, false)
    )
    setTimeout(() => {
      this.chart.data.datasets[0].data = data
      this.chart.update({
        duration: 500,
        easing: 'easeOutBounce',
        lazy: true,
      })
    }, 100)
    this.initiated = true
  }

  render() {
    if (!this.initiated) {
      this.initiateChart()
    }
    const {
      data = [],
      wide = false,
      chart_height,
      id = ''
    } = this.props
    if (this.chart & !this.rendered) {
      this.rendered = true
      this.chart.data.datasets[0].data = data
      this.chart.resize()
      this.chart.update(0)
    }
    // const width = wide ? 1024 : 570
    let height = wide ? 310 : 261
    if (chart_height) {
      height = chart_height
    }

    return (
      <div style={{ position: 'relative', display: 'flex', flex: 1 }}>
        <canvas id={id} ref={this.chartRef} width="100%" height={height}></canvas>
      </div>
    )
  }
}

export default VisualChart
