import React, { Component } from 'react'
import throttle from 'lodash/throttle'

import { parseQuery, setLocalStorage } from './common'
import './App.css'
import { baseApiUrl, baseApiUrlTech } from './Constant'
import BacktestTable from './Components/BacktestTable'
import BacktestTableResp from './Components/BacktestTableResp'
import NsLogo from './assets/text_logo.png'

class App extends Component {
  constructor(props) {
    super(props)
    this.headers = new Headers()
    this.headers.append('content-type', 'application/json')
    this.headers.append('x-csrftoken', 'token')
    this.backtestData = {}
    this.queries = {}
    this.state = {
      isFetching: true,
      ticks: {},
      displayDevice: this.resize(null, true),
    }
    this.resize = throttle(this.resize.bind(this), 3000, { leading: false })
    window.addEventListener('resize', this.resize)
    this.params = {}
    this.apiUrl = baseApiUrl
  }

  componentDidMount = async () => {
    const { search } = window.location
    this.queries = parseQuery(search)
    const { id = 'd4a9afc3-20b3-47ff-8771-c0f7fad69b9e', s, source } = this.queries
    this.params = { id, s }
    this.apiUrl = source === 'in' ? baseApiUrlTech : baseApiUrl
    const url = `${this.apiUrl}backtest_shared/?sbt=${id}` // marketplace_backtest
    const data = await fetch(url, {
      method: 'GET',
      headers: this.headers
    })
    const respData = await data.json()
    this.backtestData = respData
    if (respData && !source) {
      this.getMetaData()
    }
    if (!s) {
      const { equities = {} } = respData
      const symbols = Object.keys(equities)
      if (symbols.length) {
        const symbol = symbols[0]
        const exchange = equities[symbol] || ''
        this.params.s = `${exchange.toUpperCase()}_${symbol}`
      }
    }
    this.setState({ isFetching: false, })
  }

  getMetaData = async () => {
    const data = await fetch(`${baseApiUrl}get_crypto_pair_meta/`, {
      method: 'GET',
      headers: this.headers
    })
    const respData = await data.json()
    setLocalStorage(respData.data, true)
  }

  resize = (e, noUpdate) => {
    const currentWidth = window.innerWidth
    let displayDevice = 'desktop'
    if (currentWidth <= 1024) {
      displayDevice = 'mobile'
    } else if (currentWidth <= 1281) {
      displayDevice = 'tab'
    } else if (currentWidth <= 1366) {
      displayDevice = 'desktop'
    } else {
      displayDevice = '4k'
    }
    if (noUpdate) {
      return displayDevice
    }
    this.setState({ displayDevice })
    return null
  }

  render() {
    const { isFetching, displayDevice } = this.state
    // console.log(this.backtestData, 'results')
    const BacktestTableView = displayDevice !== 'mobile' ? BacktestTable : BacktestTableResp
    return (
      <div className="app">
        <BacktestTableView
          backtestData={this.backtestData}
          isFetching={isFetching}
          stock={this.params.s}
          displayDevice={displayDevice}
        />
        <div className="app_logo">
          <a id="app_logo_link" href="https://streak.world" className="app_logo_link" target="_blank">
            <img src={NsLogo} alt="logo" />
          </a>
        </div>
      </div>
    )
  }
}

export default App
