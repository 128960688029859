import React from 'react'

const WinLossBar = ({
  winCount,
  lossCount,
}) => {
  const greenBorder = lossCount === 0 ? '2px' : '2px 0 0 2px'
  const redBorder = winCount === 0 ? '2px' : '0 2px 2px 0'
  return (
    <div
      style={{
        flex: 1,
        display: 'flex',
        height: 5,
        maxWidth: 120,
        justifyContent: 'center',
      }}
    >
      <div
        style={{
          flex: winCount,
          backgroundColor: '#06d2a4',
          borderRadius: greenBorder,
        }}
      />
      <div
        style={{
          flex: lossCount,
          backgroundColor: '#ff4258',
          borderRadius: redBorder,
        }}
      />
    </div>
  )
}

export default WinLossBar
