import React, { useState } from 'react'
import moment from 'moment'

import styles from '../Styles/TransactionLog.module.css'
import Pagination from './Pagination'
import { getPrecision } from '../common'

const tableHeaderMap = [
  { label: 'Trigger Date', width: '26%', textAlign: 'left' },
  { label: 'Buy/Sell', width: '10%', textAlign: 'center' },
  { label: 'Qty.', width: '16%', textAlign: 'center' },
  { label: 'Price', width: '20%', textAlign: 'center' },
  { label: 'Cummulative P&L', width: '18%', textAlign: 'center' },
  { label: 'Trigger Type', width: '10%', textAlign: 'center' },
]

const headerCell = ({ label, width, textAlign }) => (
  <p className={styles.cellHeader} style={{ width, textAlign }} key={label}>{label}</p>
)

const renderBuySell = (value, mobileView) => {
  const color = value.toUpperCase() === 'BUY' ? 'blue' : '#ff5722'
  let backgroundColor = 'transparent'
  let text = value.toUpperCase()
  let extraStyles = {
    boxSizing: 'border-box',
    width: tableHeaderMap[1].width,
    textAlign: tableHeaderMap[1].textAlign
  }
  if (mobileView) {
    backgroundColor = color === 'blue' ? '#ebf5fa' : '#ffeee8'
    text = text.charAt(0)
    extraStyles = {
      marginRight: '5px',
    }
  }

return (
    <p
      style={{
        backgroundColor,
        fontSize: 11,
        color,
        padding: '4px 8px',
        borderRadius: '3px',
        ...extraStyles,
      }}
    >
      {text}
    </p>
  )
}

const getLocalDate = (dateObj) => {
  const offset = moment().utcOffset()
  const localDate = moment.utc(dateObj).utcOffset(offset).format('DD/MM/YYYY HH:mm:ss')
  return localDate
}

const TransactionLog = React.memo(({
  data = [],
  mobileView,
}) => {
  const len = data.length
  const totalPages = Math.ceil(len / 100)
  let page_limit = 100
  if (data.length < 100) {
    page_limit = data.length
  }
  const [currentPage, pageChangeHandler] = useState(0)
  const [tableData, refreshTableData] = useState(data.slice(0, currentPage + page_limit))

  const [exchange, seg_sym] = tableData[0][1].split('_')
  const precision = getPrecision(`${exchange.toUpperCase()}_${seg_sym}`, exchange.toLowerCase())

  const jumpToPage = (newPage) => {
    pageChangeHandler(newPage)
    refreshTableData(data.slice(newPage * page_limit, (newPage + 1) * page_limit))
  }
  const renderCumPnlCell = (itemData, prevDataIndex) => {
    let prevIndex = 0
    let itemIndex = 0
    if (mobileView) {
      prevIndex = prevDataIndex
    } else {
      itemIndex = (currentPage * page_limit) + prevDataIndex
      prevIndex = itemIndex - 1
    }
    let pl = '#06d2a4'
    if (itemIndex === 0) {
      pl = '#ff4258'
    } else if (data[prevIndex][5] >= itemData[5]) {
      pl = '#ff4258'
    } else {
      pl = '#06d2a4'
    }
    const styles = mobileView ? {} : {
      width: tableHeaderMap[4].width,
      textAlign: tableHeaderMap[4].textAlign,
    }
    return (
      <p
        className={`${styles.right} ${styles[pl]} ${mobileView ? styles.pnl : ''}`}
        style={styles}
      >
        {itemData[5].toFixed(precision)}
      </p>
    )
  }
  return (
    <div className={styles.container}>
      <ul className={styles.tableHeader}>
        {!mobileView ? (
          <li className={styles.headerRow}>
            {tableHeaderMap.map(item => (
              headerCell({ ...item })
            ))}
          </li>
        ) : null}
        {mobileView ? tableData.map((item, i) => {
          return (
            <li className={styles.content} key={item[0]}>
              <div className={styles.contentRow}>
                <div className={styles.cryptoDetail}>
                  {renderBuySell(item[2], mobileView)}
                  <p className={styles.cryptoPair}>
                    {item[1].replace('_', ' ')}
                  </p>
                </div>
                <div className={styles.priceContainer}>
                  <span className={styles.label}>Price:</span>
                  {item[4].toFixed(precision)}
                </div>
              </div>
              <div className={styles.contentRow}>
                <div className={styles.pnlContainer}>
                  <span className={styles.label}>P&L:</span>
                  {renderCumPnlCell(item, i)}
                </div>
                <p className={styles.triggerType}>
                    {item[7]}
                </p>
                <p className={styles.timeStamp}>
                  {getLocalDate(item[0])}
                </p>
              </div>
            </li>
          )}) : tableData.map((item, i) => (
            <li className={styles.content} key={item[0]}>
              <p className={styles.timeStamp} style={{ width: tableHeaderMap[0].width, textAlign: tableHeaderMap[0].textAlign }}>
                {getLocalDate(item[0])}
              </p>
              {/* <p className={styles.cryptoPair} style={{ width: tableHeaderMap[0].width }}>
                {item[1].replace('_', ' ')}
              </p> */}
              {renderBuySell(item[2], mobileView)}
              <p className={styles.qty} style={{ width: tableHeaderMap[2].width, textAlign: tableHeaderMap[2].textAlign }}>{item[3]}</p>
              <p className={styles.price} style={{ width: tableHeaderMap[3].width, textAlign: tableHeaderMap[3].textAlign }}>{item[4].toFixed(precision)}</p>
              {renderCumPnlCell(item, i)}
              <p className={styles.triggerType} style={{ width: tableHeaderMap[5].width, textAlign: tableHeaderMap[5].textAlign }}>
                {item[7]}
              </p>
            </li>
          ))}
      </ul>
      <Pagination
        totalPages={totalPages}
        currentPage={currentPage}
        jumpToPage={jumpToPage}
      />
    </div>
  )
})

export default TransactionLog
