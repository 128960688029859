import React from 'react'

import styles from '../Styles/Loader.module.css'

const Loader = React.memo(({
  num = 5,
  color = '',
}) => {
  return (
    <div className={styles.dot_container}>
      <div className={styles.dot} style={{ backgroundColor: color }} />
      <div className={styles.dot} style={{ backgroundColor: color }} />
      <div className={styles.dot} style={{ backgroundColor: color }} />
      {num === 5 ? <div className={styles.dot} style={{ backgroundColor: color }} /> : null}
      {num === 5 ? <div className={styles.dot} style={{ backgroundColor: color }} /> : null}
    </div>
  )
})

export default Loader
