export const baseApiUrl = 'https://api.streak.world/'
export const baseApiUrlTech = 'https://api.streak.ninja/'

export const endpoints = {

}

export const exchange_mapping = {
  COINBASE: 'coinbase',
  BITFINEX: 'bitfinex',
  BINANCE: 'binance',
  POLONIEX: 'poloniex',
  HUOBI: 'huobi',
  CCCAGG: 'cccagg',
  HITBTC: 'hitbtc',
  BITHUMB: 'bithumb',
  BITBANK: 'bitBank',
  COINBASEPRO: 'coinbase',
  OKEX: 'okex',
  KRAKEN: 'kraken',
  ZB: 'zb',
  BITMEX: 'bitmex',
  DERIBIT: 'deribit',
  NASDAQ: 'nasdaq',
  NYSE: 'nyse',
  'BINANCE-FUTURES': 'binance-futures',
  'KRAKEN-FUTURES': 'kraken-futures'
}

export const chart_options = (label = '', gradient, data, format, positive, colors, showTicks = true) => {
    const ticks = showTicks ? {
      fontColor: colors.menuItem,
      beginAtZero: true,
      fontSize: 10,
      fontWeight: '500',
      fontFamily: 'Open Sans',
      autoSkip: true,
      maxTickLimit: 20
    } : { display: false }
  
    const x_ticks = showTicks ? {
      fontColor: colors.menuItem,
      fontSize: 10,
      fontWeight: '500',
      fontFamily: 'Open Sans',
      type: 'time'
    } : { display: false }
  
    const gridLines_x = {
      color: colors.borderColor,
      zeroLineBorderDash: [5, 5],
      zeroLineColor: colors.textLight,
    }
    const gridLines_y = {
      color: 'transparent',
      zeroLineBorderDash: [5, 5],
      zeroLineColor: colors.textLight,
    }
    const lineColor = positive ? '#1ec18f' : '#ff4258'
    return {
      type: 'line',
      // scaleSteps: 4,
      data: {
        datasets: [{
          label,
          backgroundColor: gradient,
          borderColor: lineColor,
          pointBackgroundColor: lineColor,
          hoverRadius: 1,
          borderWidth: 0,
          lineTension: 0,
          strokeColor: lineColor,
          data
        }]
      },
      options: {
        responsiveAnimationDuration: 0,
        maintainAspectRatio: false,
        scales: {
          yAxes: [{
            gridLines: gridLines_x,
            ticks
          }],
          xAxes: [{
            display: showTicks,
            type: 'time',
            position: 'bottom',
            gridLines: gridLines_y,
            x_ticks,
            time: {
              displayFormats: {
                day: 'DD/MM/YY'
              }
            }
          }],
        },
        legend: {
          display: false,
        },
        layout: {
          padding: {
            left: 10,
            right: 0,
            top: 5,
            bottom: 5
          },
        },
        elements: {
          point: {
            backgroundColor: lineColor,
            borderColor: lineColor,
            hoverRadius: 2,
            radius: 0,
          },
          line: {
            borderWidth: 1
          }
        },
        tooltip: {
          mode: 'nearest'
        },
        animation: {
          duration: 0,
        }
      }
    }
  }
  