import React from 'react'

const BacktestGraph = ({
  width = 32,
  height = 32,
  color = '#000000'
}) => {
  return (
    <svg viewBox="0 0 76.7 76.7" width={width} height={height}>
      <g>
        <path
          fill={color}
          d="M70.8,66.2c0,1.1-0.9,2-2,2H10.4c-1.1,0-2-0.9-2-2V13.8c0-1.1,0.9-2,2-2s2,0.9,2,2v50.5h56.4C69.9,64.2,70.8,65.1,70.8,66.2
          z M21.9,55.4c2.4,0,4.3-1.9,4.3-4.3c0-0.9-0.3-1.8-0.8-2.5l6.7-10.5c0.3,0.1,0.6,0.1,0.9,0.1c0.2,0,0.5,0,0.7-0.1l6.7,10.1
          c-0.4,0.6-0.6,1.4-0.6,2.1c0,2.4,1.9,4.3,4.3,4.3c2.4,0,4.3-1.9,4.3-4.3c0-0.7-0.2-1.4-0.5-2l12.1-18.9c0.2,0,0.5,0.1,0.7,0.1
          c2.4,0,4.3-1.9,4.3-4.3c0-2.4-1.9-4.3-4.3-4.3c-2.4,0-4.3,1.9-4.3,4.3c0,0.6,0.2,1.3,0.4,1.8l-12.3,19c-0.2,0-0.3,0-0.5,0
          c-0.1,0-0.2,0-0.4,0l-6.9-10.4c0.3-0.6,0.4-1.2,0.4-1.8c0-2.4-1.9-4.3-4.3-4.3c-2.4,0-4.3,1.9-4.3,4.3c0,0.6,0.1,1.1,0.3,1.6
          l-7.2,11.3c-2.4,0-4.3,2-4.3,4.3C17.6,53.4,19.5,55.4,21.9,55.4z"
        />
      </g>
    </svg>
  )
}

export default BacktestGraph
