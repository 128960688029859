/*
  How to Use
  <ShowLTP
    containerClass={styles.ltpContainer}
    ltpClass={styles.ltp}
    changeClass={styles.changeValue}
    seg_sym={seg_sym}
  />
  // Replacement of GetLTP
  // CHANGE LOG:- Implemented getPrecision and ticks fetch from store.
*/

import React, { Fragment, Component } from 'react'
import PropTypes from 'prop-types'
import Worker from '../file.worker'

import styles from '../Styles/ShowLtp.module.css'
import ArrowFilledUp from '../Icons/ArrowFilledUp'
import ArrowFilledDown from '../Icons/ArrowFilledDown'
import { getPrecision } from '../common'
import { exchange_mapping } from '../Constant'

class ShowLTP extends Component {
  constructor(props) {
    super(props)
    const {
      // exchange = '',
      seg_sym = '',
    } = props
    this.state = {
      ticks: {},
    }
    const [segment = '', symbol = ''] = seg_sym.split('_')
    const precision = seg_sym ? getPrecision(`${segment.toUpperCase()}_${symbol}`, segment.toLowerCase()) : 2
    this.precision = precision
  }

  componentDidMount() {
    const { seg_sym } = this.props
    if (seg_sym) {
      this.worker = new Worker()
      this.worker.addEventListener('message', event => this.tickHandler(event))
      const [seg, sym] = seg_sym.split('_')
      const exchange = exchange_mapping[seg]
      const token = `${exchange}_${sym}`
      this.worker.postMessage({
        type: 'subscribe',
        subsList: [token],
      })
    }
  }

  tickHandler = (event) => {
    const { data } = event
    if (data.type === 'disconnection') {
      // this.showSnackbar('Please check your internet connection', 'warning')
    } 
    if (typeof data === 'object') {
      this.setState({ ticks: event.data })
    }
  }

  render() {
    const {
      seg_sym = '',
      containerClass = '',
      ltpClass = '',
      changeClass = '',
      disabled = false,
      normal,
      showIcon = false,
      iconSize = 18,
      theme,
      iconUpPos = 6,
      iconDownPos = 3,
      hideChange = false,
      containerStyles = {},
    } = this.props
    const { ticks } = this.state
    let change = 0
    let ltp = 0
    if(seg_sym && ticks[seg_sym] && ticks[seg_sym].price) {
      ({ change, price: ltp } = ticks[seg_sym])
    }
    let color = '#ff4258'
    let iconPos = iconDownPos
    if (change >= 0) {
      color = '#06d2a4'
      iconPos = iconUpPos
    }
    if (disabled) {
      color = ''
    } else if (normal) {
      color = 'inherit'
    }
    const ltpValue = (ltp).toFixed(this.precision) || ltp
    const changeValue = change > 0 ? `+${(+change).toFixed(2)} %` : `${(+change).toFixed(2)} %`
    const Icon = change >= 0 ? ArrowFilledUp : ArrowFilledDown
    return (
      <div className={`${styles.ltpContainer} ${containerClass}`} style={containerStyles}>
        <p className={`${styles.ltp} ${ltpClass}`}>
          {showIcon ? (
            <Fragment>
              {<span style={{ position: 'relative', top: iconPos }}>
                <Icon
                  height={iconSize}
                  width={iconSize}
                  color={theme.colors[color]}
                />
              </span>}
              &nbsp;
            </Fragment>)
            : null}
          {ltpValue}
        </p>
        {!hideChange ? (
          <p className={`${styles.changeValue} ${changeClass}`} style={{ color }}>
            {` (${changeValue})`}
          </p>) : null}
      </div>
    )
  }
}

ShowLTP.propTypes = {
  seg_sym: PropTypes.string,
}

export default ShowLTP
