import React from 'react'

import styles from '../Styles/PerformanceMetrics.module.css'

const cellRenderer = (label, value) => (
  <div className={styles.cell}>
    <p className={styles.cellLabel}>{label}</p>
    <p className={styles.cellValue}>{value}</p>
  </div>
)

const PerformanceMetrics = React.memo(({
  total_number_of_signals,
  winning_streak,
  lossing_streak,
  maximum_gain,
  maximum_loss,
  average_gain_per_winning_trade,
  average_gain_per_losing_trade,
  max_draw,
  precision,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.row}>
        {cellRenderer('Total Number of Signals', total_number_of_signals)}
        {cellRenderer('Max Drawdown', `${(+max_draw).toFixed(2)} %`)}
      </div>
      <div className={styles.row}>
        {cellRenderer('Number of Wins', maximum_gain.toFixed(precision))}
        {cellRenderer('Number of Losses', maximum_loss.toFixed(precision))}
      </div>
      <div className={styles.row}>
        {cellRenderer('Winning Streak', winning_streak)}
        {cellRenderer('Losing Streak', lossing_streak)}
      </div>
      <div className={styles.row}>
        {cellRenderer('Avg Gain / Winning Trade', average_gain_per_winning_trade.toFixed(precision))}
        {cellRenderer('Avg Loss / Lossing Trade', average_gain_per_losing_trade.toFixed(precision))}
      </div>
    </div>
  )
})

export default PerformanceMetrics
