export const parseQuery = (str, decode) => {
    const queries = {}
    let query = str.substring(1)
    query = query.split('&')
    for (let j = 0; j < query.length; j++) {
      const [key, value] = query[j].split('=')
      if (decode) {
        queries[key] = atob(value)
      } else {
        queries[key] = decodeURIComponent(value)
      }
    }
    return queries
  }
  
export const getPrecision = (seg_sym, exchange) => {
  let precision = 4
  const f_exchange = exchange === 'coinbase' ? 'coinbasepro' : exchange
  const token = `${seg_sym.toUpperCase()}_${f_exchange.toLowerCase()}`
  try {
    const metaData = getLocalStorage(token)
    const meta = metaData ? JSON.parse(metaData) : null
    if (meta) {
      const { quote_increment = '' } = meta
      const quoteIncrement = quote_increment.toString()
      const minSizeSplitArray = quoteIncrement.split('.')
      if (minSizeSplitArray.length === 2) {
        const matchResult = minSizeSplitArray[1].match(/[1-9]/)
        precision = matchResult.index + 1
      }
    }
  }catch(e) {
    // eslint-disable-next-line no-console
    console.log('Error in getPrecision')
  }
  return precision
}

export const setLocalStorage = (item, convert) => {
  Object.keys(item).forEach((key) => {
    localStorage.setItem(key, convert ? JSON.stringify(item[key]) : item[key])
  })
}

export const getLocalStorage = (key) => {
  return localStorage.getItem(key)
}

export const getMetaObj = (seg_sym = '', exchange = '') => {
  let quotePrecision = 4
  let basePrecision = 2
  let quoteCurrency = ''
  let baseCurrency = ''
  let orderTypes = []
  const f_exchange = exchange === 'coinbase' ? 'coinbasepro' : exchange
  const token = `${seg_sym.toUpperCase()}_${f_exchange.toLowerCase()}`
  try {
    const metaData = getLocalStorage(token)
    const meta = metaData ? JSON.parse(metaData) : null
    if (meta) {
      const {
        quote_increment = '', quote_currency = '', base_currency = '', base_min_size = '', order_types = []
      } = meta
      baseCurrency = base_currency
      quoteCurrency = quote_currency
      orderTypes = order_types
      const quoteIncrement = quote_increment.toString()
      const minSizeSplitArray = quoteIncrement.split('.')
      if (minSizeSplitArray.length === 2) {
        const matchResult = minSizeSplitArray[1].match(/[1-9]/)
        quotePrecision = matchResult.index + 1
      }
      const minSize = base_min_size.toString()
      const baseSizeSplitArray = minSize.split('.')
      if (baseSizeSplitArray.length === 2) {
        if(parseInt(baseSizeSplitArray[1], 10) > 0) {
          const matchResult = baseSizeSplitArray[1].match(/[1-9]/)
          basePrecision = matchResult.index + 1
        }else{
          basePrecision = 0
        }
      }
    }
  }catch(e) {
    // eslint-disable-next-line no-console
    console.log('Error in getPrecision')
  }
  const returnObj = {
    quotePrecision,
    quoteCurrency,
    orderTypes,
    baseCurrency,
    basePrecision
  }
  return returnObj
}