import React from 'react'

const MenuClose = ({
  width = 32,
  height = 32,
  color = '#22283c',
  className = ''
}) => {
  return (
    <svg viewBox="0 0 64 64" width={width} height={height} className={className}>
      <path
        fill={color}
        d="M59.2,55.5c0.8,0.8,0.8,2,0,2.8c-0.4,0.4-0.9,0.6-1.4,0.6s-1-0.2-1.4-0.6L32.8,34.8L9.3,58.4C8.9,58.8,8.4,59,7.9,59
s-1-0.2-1.4-0.6c-0.8-0.8-0.8-2,0-2.8L30,32L6.5,8.5c-0.8-0.8-0.8-2,0-2.8c0.8-0.8,2-0.8,2.8,0l23.5,23.5L56.4,5.6
c0.8-0.8,2-0.8,2.8,0c0.8,0.8,0.8,2,0,2.8L35.7,32L59.2,55.5z"
      />
    </svg>
  )
}

export default MenuClose
