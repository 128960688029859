import React from 'react'

const ArrowFilledDown = ({
  width = 32,
  height = 32,
  color = '#000000',
  className = ''
}) => {
  return (
    <svg viewBox="0 0 64 64" width={width} height={height} className={className}>
      <g>
        <polygon fill={color} points="62,21 32,51 2,21" />
      </g>
    </svg>
  )
}

export default ArrowFilledDown
