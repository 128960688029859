import React, { Fragment } from 'react'

import styles from '../Styles/Pagination.module.css'

const NumberPagination = React.memo(({
  totalPages = 0,
  currentPage = 0,
  containerClass = '',
  jumpToPage,
}) => {
  const currentDisplay = currentPage + 1 > totalPages ? currentPage : currentPage + 1
  const pageChangehandler = (page) => {
    if (page === currentPage) {
      return
    }
    if (page < 0) {
      jumpToPage(totalPages - 1)
    } else if (page > totalPages - 1) {
      jumpToPage(0)
    } else {
      jumpToPage(page)
    }
  }
  const renderPageBtn = (pageNoArr) => {
    const view = pageNoArr.map((page, index) => {
      const key = `${index}_${page}`
      if (page === 'dot') {
        return <div className={styles.pageDots} key={key}>...</div>
      }
      return (
        <button
          key={key}
          type="button"
          className={`${styles.pageBtn} ${currentDisplay === page ? styles.selectedPage : ''}`}
          onClick={() => pageChangehandler(page - 1)}
        >
          {page}
        </button>
      )
    })
    return view
  }
  const renderPages = () => {
    let view = null
    if (totalPages <= 5) {
      const pageNoArr = []
      for(let i = 2; i < totalPages; i++) {
        pageNoArr.push(i)
      }
      view = renderPageBtn(pageNoArr)
    } else if (totalPages > 5 && currentDisplay > 3) {
      if (currentDisplay === totalPages) {
        view = renderPageBtn([2, 'dot', currentDisplay - 1])
      } else if (currentDisplay + 1 === totalPages) {
        view = renderPageBtn(['dot', currentDisplay - 1, currentDisplay])
      } else if (currentDisplay + 2 === totalPages) {
        view = renderPageBtn(['dot', currentDisplay - 1, currentDisplay, currentDisplay + 1])
      } else {
        view = renderPageBtn(['dot', currentDisplay - 1, currentDisplay, currentDisplay + 1, 'dot'])
      }
    } else {
      view = renderPageBtn([2, 3, 'dot'])
    }
    return (
      <Fragment>
        {view}
      </Fragment>
    )
  }
  if(totalPages <= 1) {
    return null
  }
  return (
    <div className={`${styles.container} ${containerClass}`}>
      <button
        type="button"
        className={styles.leftArrowBtn}
        onClick={() => pageChangehandler(currentPage - 1)}
      >
        1
        {/* <KeyboardArrowLeft className={styles.leftArrow} /> */}
      </button>
      <button
        type="button"
        className={`${styles.pageBtn} ${currentDisplay === 1 ? styles.selectedPage : ''}`}
        onClick={() => pageChangehandler(0)}
      >
        1
      </button>
      {renderPages()}
      {totalPages > 1 ? (
        <button
          type="button"
          className={`${styles.pageBtn} ${currentDisplay === totalPages ? styles.selectedPage : ''}`}
          onClick={() => pageChangehandler(totalPages - 1)}
        >
          {totalPages}
        </button>) : '...'}
      <button
        type="button"
        className={styles.rightArrowBtn}
        onClick={() => pageChangehandler(currentPage + 1)}
      >
        0
        {/* <KeyboardArrowRight className={styles.rightArrow} /> */}>
      </button>
    </div>
  )
})

export default NumberPagination
